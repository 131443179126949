<!--
 * @Date: 2021-03-03 21:08:23
 * @LastEditors: frank
 * @LastEditTime: 2021-05-08 09:11:48
 * @FilePath: /shop_frontend/src/views/pageDecoration/navModules.vue
 * @Description: Description 文本模块
-->
<template>
  <section class="navModules">
    <a-row>
      <a-col span="24">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="文本内容" prop="content">
            <a-input v-model="form.content" />
          </a-form-model-item>
          <a-form-model-item label="字体大小">
            <a-radio-group :options="options" v-model="form.size" />
          </a-form-model-item>
          <a-form-model-item label="字体颜色">
            <!-- <input type="color" v-model="form.font_color" /> -->
            <div class="color__box">
            <colorPicker v-model="form.font_color" defaultColor="#ff0000" />
            </div>
          </a-form-model-item>
          <a-form-model-item label="背景颜色">
            <!-- <input type="color" v-model="form.background_color" /> -->
            <div class="color__box">
            <colorPicker
              v-model="form.background_color"
              defaultColor="#ff0000"
            />
            </div>
          </a-form-model-item>
          <a-form-model-item label="显示位置">
            <a-radio-group :options="positionOptions" v-model="form.position" />
          </a-form-model-item>
          <a-form-model-item label="链接">
            <div class="flex">
              <a-input v-model="form.link" allowClear> </a-input>
              <a-button type="primary" style="margin-left: 20px;" @click="openLink">选择链接</a-button>
            </div>
          </a-form-model-item>
          <a-form-model-item label="当前排序">
            <a-input v-model="form.index" class="w220" />
          </a-form-model-item>
          <!-- <a-form-model-item label="是否显示">
            <a-checkbox v-model="form.is_visible"></a-checkbox>
          </a-form-model-item> -->
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="onSubmit"> 保存 </a-button>
                    <a-button type="danger" @click="onDelete" style="margin-left: 10px;"> 删除 </a-button>

          </a-form-model-item>
        </a-form-model>
      </a-col>
    </a-row>
    <chooseLink :visible="visible" @ok="handleOk"
      @cancel="handleCancel"></chooseLink>
  </section>
</template>

<script>
export default {
  name: "navModules",
  data() {
    return {
      columns: [
        {
          title: "文本内容",
          dataIndex: "content",
          key: "content",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 300,
        },
      ],
      options: [
        { label: "大", value: 0 },
        { label: "中", value: 1 },
        { label: "小", value: 2 },
      ],
      positionOptions: [
        { label: "居左", value: 0 },
        { label: "居中", value: 1 },
        { label: "居右", value: 2 },
      ],
      form: {
        content: "",
        size: 0,
        font_color: "",
        background_color: "",
        position: 0,
        link: "",
        is_visible: false,
        nav_module_items: [{}],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules: {},
      visible: false,
    };
  },
  props: {
    info: {
      type: Array,
    },
  },
  components: {
    chooseLink: () => import("@/components/chooseLink"),
  },
  computed: {},
  watch: {
    info: {
      handler () {
        this.onEdit(this.info);
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    openLink () {
      this.visible = true
    },
    handleOk (list) {

      console.log(list, 'list');
      this.form.link = list[0] ? list[0].link : ''

      this.visible = false
    },
    handleCancel () {
      this.visible = false
    },
    onEdit(record) {
      this.form = { ...this.form, ...record };
    },
    onDelete () {
      this.$axios.delete('/text_modules/' + this.form.id + '/').then(() => {
        this.$emit('refreshDel')
      })
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let request = this.form.id
            ? this.$axios.put("/text_modules/" + this.form.id + "/", this.form)
            : this.$axios.post("/text_modules/", this.form);
          request.then((res) => {
            console.log(res);
            this.$message.success("操作成功");
            this.$emit("refresh");
          });
          //   alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
</style>
